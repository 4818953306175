import { Geolocation } from "@veti/website-types";
import React, { useContext } from "react";

import { AbstractApiLocation } from "../types";

interface ILocationContext {
  getLocation(): Promise<Geolocation>;
}

const LocationContext = React.createContext<ILocationContext | undefined>(
  undefined
);

const getLocation = (): Promise<Geolocation> => {
  const storage = window.localStorage;
  const locationStr = storage.getItem("location");
  if (locationStr !== null) {
    return Promise.resolve(JSON.parse(locationStr) as Geolocation);
  } else {
    return new Promise((resolve, reject) => {
      fetch(
        "https://ipgeolocation.abstractapi.com/v1/?api_key=8fc75a9a1211496d88fc655080d64dcf"
      )
        .then((response) => response.json() as Promise<AbstractApiLocation>)
        .then((data) => {
          // Trnaslation between geolocation provider and our model
          const loc: Geolocation = {
            countryCode: data.country_code,
            country: data.country,
            region: data.region,
            city: data.city,
            latitude: data.latitude,
            longitude: data.longitude,
            ipAddress: data.ip_address,
          };

          storage.setItem("location", JSON.stringify(loc));
          resolve(loc);
        })
        .catch((error) => reject(error));
    });
  }
};

export function LocationProvider({
  children,
}: {
  children: React.ReactElement;
}) {
  const value = {
    getLocation: getLocation,
  };

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
}

export function useLocation() {
  const context = useContext(LocationContext);
  if (context === undefined) {
    throw new Error("useLocation must be used inside LocationProvider");
  }

  return context;
}
