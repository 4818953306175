export const isNameValid = (name: string) => {
  const trimmed = name.trim();
  return !/[@!#$%\\[\]{}*]/.test(trimmed) && trimmed.length > 2;
};

export const isNameValidEmpty = (name: string) => {
  const trimmed = name.trim();
  return !/[@!#$%\\[\]{}*]/.test(trimmed);
};

export const isEmailValid = (email: string) => {
  const trimmed = email.trim();
  return /(.+)@(.+){2,}\.(.+){2,}/.test(trimmed);
};

export const isPhoneValid = (phone: string) => {
  const trimmed = phone.trim();
  return /^\+?[0-9]{6,}$/.test(trimmed);
};

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};
