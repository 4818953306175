import { AccountPlan, AccountPlanPricing } from "../../types";

export const fallbackPricing: Array<AccountPlan | AccountPlanPricing> = [
  {
    available: true,
    code: "VETIPB",
    trialDays: 15,
    productCode: "VETI",
    description:
      "Diseñado para todos los médicos veterinarios independientes que entregan servicios a domicilio y para consultas pequeñas",
    name: "Plan Básico",
    features: [
      "Consultas Médicas",
      "Cirugías",
      "Inmunizaciones",
      "Desparasitaciones",
      "Recordatorios",
      "Agenda",
      "1 usuario",
    ],
    type: "plan",
  },
  {
    currency: "USD",
    basePrice: 15,
    planCode: "VETIPB",
    country: "worldwide",
    code: "WW1",
    monthlyPeriodicity: 1,
    discountPercent: 0,
    pricePerUser: 6,
    productCode: "VETI",
    type: "plan_pricing",
  },
  {
    currency: "USD",
    basePrice: 60,
    planCode: "VETIPB",
    country: "worldwide",
    code: "WW2",
    monthlyPeriodicity: 12,
    discountPercent: 10,
    pricePerUser: 10,
    productCode: "VETI",
    type: "plan_pricing",
  },
];
