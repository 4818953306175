import React, { useEffect, useRef, useState } from "react";

interface SchedulerColumnProps {
  dayName: string;
  dayNumber: string;
  border: boolean;
  children: React.ReactNode;
}

const SchedulerColumn = (props: SchedulerColumnProps) => {
  return (
    <div
      className={`flex flex-col h-5/6 pt-2 ${
        props.border ? "border-r border-gray-300 border-dashed" : ""
      }`}
    >
      <div className="flex flex-col flex-shrink-0 justify-center bg-white rounded-full w-12 h-12 mx-auto my-3">
        <p className="text-xs text-center text-gray-400">
          <small>{props.dayName}</small>
        </p>
        <p className="text-md font-bold text-center text-gray-500 -mt-1">
          {props.dayNumber}
        </p>
      </div>
      {props.children}
    </div>
  );
};

interface EventProps {
  colorClass: string;
  height: string;
  title?: string;
  time?: string;
}

const Event = (props: EventProps) => {
  return (
    <div className={`w-full h-${props.height} p-1 self-center`}>
      <div className={`w-full h-full ${props.colorClass} rounded-md p-1`}>
        {props.title && (
          <p className="text-white text-xxs font-semibold md:text-xs">
            {props.title}
          </p>
        )}
        {props.time && (
          <p className="text-white text-xxs font-semibold md:font-normal">
            {props.time}
          </p>
        )}
      </div>
    </div>
  );
};

export const Scheduler = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [leftOffset, setLeftOffset] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setLeftOffset(containerRef.current.offsetLeft);
    }
  }, []);

  window.addEventListener("resize", () => {
    if (containerRef.current) {
      setLeftOffset(containerRef.current.offsetLeft);
    }
  });

  return (
    <div
      className="grid grid-cols-1 text-center mt-0 lg:grid-cols-2 lg:mt-32 lg:text-left relative"
      ref={containerRef}
    >
      <div
        className="bg-scheduler-pattern bg-no-repeat -mt-20 bg-top md:bg-right md:mt-auto w-screen absolute"
        style={{ zIndex: -10, height: "450px", marginLeft: `-${leftOffset}px` }}
      ></div>
      <div className="">
        <h4 className="text-lg text-indigo-400 font-bold mt-20">
          Agenda Médica Veterinaria
        </h4>
        <h2 className="text-4xl text-gray-800 font-bold  lg:pr-7">
          Organízate y lleva el control de tus actividades
        </h2>
        <p className="text-gray-500 my-6 text-lg lg:text-base lg:pr-7">
          Todas tus actividades ordenadas de una manera amigable y simple de
          usar. Escoge tu vista preferida: diaria, semanal o mensual. Puedes
          filtrar por tipo de actividad o por usuario. Veti te ayuda a organizar
          y ser más eficiente en tus tiempos.
        </p>
      </div>
      <div className="">
        <div className="h-80 bg-white bg-opacity-20 shadow-xl rounded-md relative mx-auto w-80 sm:w-96">
          <div className="bg-indigo-400 bg-opacity-70 w-full h-7 rounded-tl-md rounded-tr-md flex">
            <div className="w-3 h-3 rounded-full border bg-red-400 border-red-500 ml-2 mr-2 self-center"></div>
            <div className="w-3 h-3 rounded-full border bg-yellow-300 border-yellow-400 mr-2 self-center"></div>
            <div className="w-3 h-3 rounded-full border bg-green-400 border-green-500 mr-2 self-center"></div>
          </div>
          <div className="grid grid-cols-3 px-4">
            <SchedulerColumn dayName="Lun" dayNumber="17" border={true}>
              <Event
                colorClass="bg-green-500"
                height="14"
                title="Desparasitación Cleo"
              />
              <Event
                colorClass="bg-yellow-300"
                height="20"
                title="Consulta Moises"
                time="10:00 - 10:30"
              />
            </SchedulerColumn>
            <SchedulerColumn dayName="Mar" dayNumber="18" border={true}>
              <Event
                colorClass="bg-blue-400"
                height="24"
                title="Cirugía Tomás"
                time="09:00 - 10:45"
              />
              <Event
                colorClass="bg-green-300"
                height="16"
                title="Inmunización Pup"
                time="10:45 - 11:10"
              />
            </SchedulerColumn>
            <SchedulerColumn dayName="Mie" dayNumber="19" border={false}>
              <Event colorClass="bg-opacity-0" height="20" />
              <Event
                colorClass="bg-gray-400"
                height="24"
                title="Ocupado"
                time="10:15 - 10:45"
              />
              <Event
                colorClass="bg-green-500"
                height="20"
                title="Desparacitación Rex"
                time="10:45 - 11:10"
              />
            </SchedulerColumn>
          </div>
        </div>
      </div>
    </div>
  );
};
