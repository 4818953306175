import { Switch } from "@headlessui/react";
import { useState } from "react";

import { DynamicHeroIcon } from "../../components/DynamicHeroIcon";
import { classNames } from "../../helpers";
import { AccountPlan, AccountPlanPricingExt } from "../../types";

const PricingFeature = ({ name }: { name: string }) => {
  return (
    <div className="flex items-center py-1 ml-2">
      <DynamicHeroIcon
        icon="CheckIcon"
        className="text-green-500 w-4 h-4 mr-3"
      />
      <p className="text-sm text-gray-500">{name}</p>
    </div>
  );
};

interface PricingPlanProps {
  name: string;
  description: string;
  price: string;
  currency: string;
  priceSuffix: string;
  buttonText: string;
  features: string[];
  discount: number;
  priceNote?: string;
  buttonUrl?: string;
  footnotes?: string[];
}

const PricingPlan = ({
  name,
  description,
  price,
  currency,
  priceSuffix,
  buttonText,
  buttonUrl,
  priceNote,
  features,
  discount,
  footnotes,
}: PricingPlanProps) => {
  const currencyFormatter = new Intl.NumberFormat();

  return (
    <div className="border border-gray-300 rounded-md p-4 bg-white h-full">
      <div className="flex items-center">
        <h2 className="text-2xl font-bold text-gray-600 mb-2 flex-1">{name}</h2>
        {discount > 0 && (
          <div className="px-3 py-1 text-xs font-medium align-middle border-green-500 border text-green-500 rounded-md">{`${discount}% dcto.`}</div>
        )}
      </div>

      <p className="text-sm text-gray-500 h-20">{description}</p>
      <p className="text-sm text-gray-400 leading-3 mt-6">{currency}</p>
      <div className={`flex items-baseline`}>
        <p className="text-4xl font-black text-gray-700">
          ${currencyFormatter.format(parseInt(price))}
        </p>
        <small className="ml-3 text-gray-400">{priceSuffix}</small>
      </div>
      {priceNote && (
        <div className="mb-6">
          <p className="text-gray-400 font-light">{priceNote}</p>
        </div>
      )}
      <div className="flex justify-center">
        <a
          className="inline-block px-5 py-2 rounded-md bg-yellow-400 text-sm tracking-wide font-semibold text-white mb-4"
          href={buttonUrl}
        >
          {buttonText}
        </a>
      </div>
      <p className="uppercase text-sm font-medium text-gray-500 border-t border-gray-300 pt-4">
        Incluye
      </p>
      <div className="my-4">
        {features.map((feat) => (
          <PricingFeature name={feat} key={feat} />
        ))}
      </div>
      {footnotes && (
        <div className="flex flex-col">
          {footnotes.map((footnote) => (
            <p className="text-gray-400 text-sm" key={footnote}>
              <small>{footnote}</small>
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

interface PricingProps {
  plans: AccountPlan[] | undefined;
  pricings: AccountPlanPricingExt[] | undefined;
}

export const Pricing = ({ plans, pricings }: PricingProps) => {
  const [yearly, setYearly] = useState(false);

  return (
    <div className="mt-6">
      <h2 className="text-4xl text-gray-800 font-bold text-center mb-10">
        Nuestros Planes
      </h2>
      {plans && pricings ? (
        <>
          <Switch.Group
            as="div"
            className="flex items-center justify-center mb-5"
          >
            <Switch
              checked={yearly}
              onChange={setYearly}
              className={classNames(
                yearly ? "bg-indigo-500" : "bg-gray-200",
                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  yearly ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3">
              <span className="text-sm font-medium text-gray-900">
                Facturación anual{" "}
              </span>
              <span className="text-sm text-gray-500">(10% descuento)</span>
            </Switch.Label>
          </Switch.Group>
          <div className="flex justify-center flex-wrap">
            {pricings
              .filter(
                (pricing) => pricing.monthlyPeriodicity === (yearly ? 12 : 1)
              )
              .map((pricing) => {
                const plan = plans.filter(
                  (plan) => plan.code === pricing.planCode
                )[0];
                return (
                  <div
                    className="w-full md:w-2/5 xl:w-1/4 my-2 mx-3"
                    key={plan.code}
                  >
                    <PricingPlan
                      name={plan.name}
                      description={plan.description}
                      price={pricing.basePrice.toString()}
                      currency={pricing.currency}
                      priceSuffix={`${
                        pricing.basePriceIncludesVAT !== undefined
                          ? "+ " + pricing.VATName
                          : ""
                      } / ${yearly ? "año" : "mes"}`}
                      priceNote={pricing.priceNote ?? " "}
                      buttonText={
                        plan.available
                          ? `Pruébalo por ${plan.trialDays} días`
                          : `Próximamente`
                      }
                      buttonUrl="#demo-form"
                      features={plan.features}
                      footnotes={[
                        `* ${pricing.pricePerUser} ${
                          pricing.currency === "CLP" ? "UF" : pricing.currency
                        } por usuario adicional`,
                      ]}
                      discount={pricing.discountPercent}
                    />
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <p>Calculando precios...</p>
      )}
    </div>
  );
};
