import * as HIcons from "@heroicons/react/solid";

interface DynamicHeroIconProps {
  icon: string;
  className?: string;
}

export const DynamicHeroIcon = (props: DynamicHeroIconProps) => {
  const { ...icons } = HIcons;
  // @ts-ignore
  const TheIcon: JSX.Element = icons[props.icon];

  return (
    <>
      {/* @ts-ignore */}
      <TheIcon {...props} />
    </>
  );
};
