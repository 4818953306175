import { Route, Routes } from "react-router-dom";

import SinglePageWebsite from "./Pages/SinglePageWebsite";
import TermsAndConditions from "./Pages/TermsAndConditions";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<SinglePageWebsite />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </>
  );
}

export default App;
