export const WhatsappButton = () => {
  return (
    <a
      href="https://wa.me/+56939225658"
      target="_blank"
      className="fixed block right-0 top-3/4"
      rel="noreferrer"
      style={{ zIndex: 99999 }}
    >
      <svg
        width="66px"
        height="66px"
        viewBox="0 0 66 66"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <path
            d="M0,50 L3.53199482,37.161491 C1.35358138,33.4043536 0.207489987,29.1417167 0.208888961,24.7751003 C0.214487159,11.1140503 11.3816508,0 25.1044431,0 C31.7637123,0.00323373024 38.0140615,2.58320545 42.7149953,7.26639757 C47.4145318,11.9495885 50.0023297,18.1743492 50,24.7950605 C49.9939418,38.4551825 38.8249055,49.5710895 25.1044431,49.5710895 C25.1034957,49.5710895 25.1053905,49.5710895 25.1044431,49.5710895 L25.093724,49.5710895 C20.9276018,49.5694052 16.8337506,48.5289862 13.1973114,46.5547973 L0,50 Z"
            id="path-1"
          ></path>
          <filter
            x="-23.0%"
            y="-23.0%"
            width="150.0%"
            height="150.0%"
            filterUnits="objectBoundingBox"
            id="filter-2"
          >
            <feOffset
              dx="1"
              dy="1"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="4"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <linearGradient
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            id="linearGradient-whatsapp"
          >
            <stop stopColor="#59D265" offset="0%"></stop>
            <stop stopColor="#29B33C" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="WhatsApp" transform="translate(7.000000, 7.000000)">
            <g id="path400-path" fill="black" fillOpacity="1">
              <use filter="url(#filter-2)" xlinkHref="#path-1"></use>
            </g>
            <path
              d="M7.80826781,36.9745417 L7.29924564,36.1793347 C5.15962065,32.8316949 4.03033485,28.9627642 4.03225806,24.990522 C4.03660007,13.3988933 13.6232511,3.96825397 25.411654,3.96825397 C31.12021,3.97061968 36.4854046,6.1600486 40.5204973,10.1337114 C44.5555901,14.1073755 46.776118,19.389955 46.7741935,25.007583 C46.769372,36.6001592 37.1822389,46.031746 25.4034668,46.031746 L25.3952809,46.031746 C21.5595592,46.0298535 17.7984802,45.0166488 14.5180117,43.1006652 L13.7369015,42.6457198 L5.6484171,44.732308 L7.80826781,36.9745417 Z"
              id="path347-path"
              fill="url(#linearGradient-whatsapp)"
            ></path>
            <path
              d="M13.8085855,42.0693507 L14.5648758,42.5154337 C17.7411066,44.3921459 21.3826762,45.384581 25.0965163,45.3864371 L25.104444,45.3864371 C36.5094101,45.3864371 45.7914448,36.148168 45.7961088,24.7932045 C45.7981375,19.2907207 43.6480022,14.1164182 39.7411232,10.2242021 C35.8342475,6.3319875 30.6395344,4.18743881 25.1123694,4.18511803 C13.6985465,4.18511803 4.41650942,13.4224577 4.41231006,24.7764932 C4.41045146,28.6673164 5.50431728,32.4569457 7.57548839,35.7359691 L8.06786939,36.5148767 L5.97711515,44.1136307 L13.8085855,42.0693507 Z M0,50 L3.53199471,37.161491 C1.35358133,33.4043536 0.207489981,29.1417167 0.208888955,24.7751003 C0.214487153,11.1140503 11.3816504,0 25.1044423,0 C31.7637113,0.00323373024 38.0140603,2.58320545 42.714994,7.26639757 C47.4145303,11.9495885 50.0023281,18.1743492 50,24.7950605 C49.9939402,38.4551825 38.8249043,49.5710895 25.1044423,49.5710895 C25.1034949,49.5710895 25.1053897,49.5710895 25.1044423,49.5710895 L25.0937232,49.5710895 C20.9276012,49.5694052 16.83375,48.5289862 13.197311,46.5547973 L0,50 Z"
              id="path400"
              fill="#FFFFFF"
              fillRule="nonzero"
            ></path>
            <path
              d="M19.4644344,14.5980891 C18.9885017,13.5465226 18.4878209,13.5252169 18.0356845,13.5067523 C17.6654091,13.4911263 17.2418293,13.4920635 16.8187248,13.4920635 C16.395145,13.4920635 15.7074222,13.6502109 15.1258323,14.2822872 C14.5437657,14.9143624 12.9032258,16.4422336 12.9032258,19.5500562 C12.9032258,22.6578799 15.17866,25.6605941 15.4956311,26.0824516 C15.8130784,26.503835 19.8880156,33.0849955 26.3421388,35.6166123 C31.7054248,37.7206904 32.7967384,37.3021471 33.961347,37.1970377 C35.125478,37.0919294 37.7178823,35.6691675 38.2466434,34.1943245 C38.7758811,32.7194812 38.7758811,31.4558039 38.6173944,31.1916102 C38.4584345,30.9278904 38.0353324,30.7702269 37.399959,30.4544257 C36.7650658,30.1386244 33.6434238,28.6102794 33.0613576,28.3995875 C32.4792921,28.188896 32.0561875,28.0837863 31.6326077,28.7158625 C31.2095031,29.3479377 29.9934948,30.7702269 29.6227428,31.1916102 C29.2524677,31.6134674 28.8821923,31.6660216 28.2468214,31.3502204 C27.6119278,31.0334726 25.5668445,30.3673081 23.1410148,28.21541 C21.2534664,26.5412383 19.9793946,24.4736177 19.6086429,23.8415414 C19.2383661,23.2094662 19.5691402,22.8676252 19.887539,22.5527705 C20.1726224,22.2696381 20.5224336,21.8151118 20.8403562,21.4462839 C21.156852,21.0774548 21.2629845,20.8142077 21.4742993,20.3932981 C21.686089,19.9714406 21.5804318,19.6026114 21.4214698,19.2868102 C21.2629845,18.9710097 20.0288916,15.8470884 19.4644344,14.5980891"
              id="path418"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </svg>
    </a>
  );
};
