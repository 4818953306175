import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "./Testimonials.css";

import { useWindowSize } from "../../hooks/useWindowSize";
import IgIcon from "../../img/IgIcon";
import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";

SwiperCore.use([Navigation, Pagination, Autoplay]);

interface TestimonialProps {
  name: string;
  title: string;
  image: string;
  children: React.ReactNode;
  ig?: string;
}

const Testimonial = (props: TestimonialProps) => {
  return (
    <div className="flex flex-col items-center space-y-7 mb-16 mt-32">
      <div
        style={{
          backgroundImage: `url('img/${props.image}.jpg')`,
        }}
        className={`w-48 h-48 rounded-full bg-red-500 bg-cover`}
      ></div>
      <div className="text-center">
        <p className="text-indigo-500 font-bold text-lg leading-3">
          {props.name}
        </p>
        <small className="text-gray-400">{props.title}</small>
        <div className="mt-3 flex justify-center">
          {props.ig && (
            <a
              href={`https://instagram.com/${props.ig}`}
              className="fill-current text-gray-400"
            >
              <IgIcon />
            </a>
          )}
        </div>
      </div>
      <div className="text-base font-normal text-gray-600 text-center w-full md:w-3/4 lg:w-2/4">
        <em>&quot;{props.children}&quot;</em>
      </div>
    </div>
  );
};

export const Testimonials = () => {
  const windowSize = useWindowSize();
  return (
    <div className="testimonials-container">
      <Swiper
        navigation={windowSize.width !== undefined && windowSize.width >= 1024}
        pagination={{
          clickable: true,
        }}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: true,
        }}
      >
        <SwiperSlide>
          <Testimonial
            image="testimonial-11"
            name="Dra. Josefina Pareja"
            title="Veterinaria Dunstan"
          >
            <>
              Veti ha sido el sistema veterinario{" "}
              <strong className="font-bold">más amigable y simple</strong> que
              he ocupado, teniendo las mismas capacidades o más que otros,
              permitiéndome{" "}
              <strong className="font-bold">
                dedicar más tiempos a mis pacientes y menos a la administración.
              </strong>
            </>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image="testimonial-1"
            name="Dra. Daniela Orlandi"
            title="Médico Veterinario, Patagonia Vet SpA."
          >
            <>
              Veti es una app rápida y muy fácil de usar; la puedes usar desde
              tu teléfono, tablet o computador. Mantiene tus fichas ordenadas,
              hace recuerdo de los calendarios de vacunas y desparasitación y
              siempre están en constante actualización.
              <br />
              <strong className="font-bold">100% recomendado!</strong>
            </>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image="testimonial-12"
            name="Dra. Cynthia Farías Aguilera"
            title="Médico Veterinario Independiente"
          >
            <>
              <strong className="font-bold">
                Mi experiencia con Veti ha sido fantástica.
              </strong>{" "}
              <br />
              Realizo consultas a domicilio, así que necesitaba algo práctico y
              rápido. <br />
              <strong className="font-bold">
                !Totalmente recomendada la aplicación!
              </strong>
            </>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image="testimonial-2"
            name="Dra. Loreto Ardiles"
            title="Médico Veterinario Independiente"
          >
            <>
              Desde que implementé el software en mi trabajo diario,{" "}
              <strong className="font-bold">
                obtuve una mejora al 100% en cuanto a la organización de mi
                información de pacientes y tutores.
              </strong>{" "}
              En usa sola plataforma, fácil de usar y completa, obtengo todo lo
              que necesito para la práctica diaria. Además el equipo de soporte
              es muy eficaz y atento para resolver mis dudas y avisarme de
              nuevas actualizaciones.
            </>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image="testimonial-3"
            name="Dra. Daniela Boggiano"
            title="Médico Veterinario, Dipl. Etología Clínica"
          >
            <>
              Estoy muy contenta de tener el programa Veti como apoyo a mi
              trabajo, el equipo humano es muy comprometido y la atención
              personalizada, muchas de las funciones han ayudado a mi trabajo
              diario, perfecto para consultas a domicilio, fichas ordenadas y el
              recordatorio es lo mejor de todo, los tutores de mis pacientes lo
              agradecen también.{" "}
              <strong className="font-bold">Una Muy buena inversión</strong>
            </>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image="testimonial-4"
            name="Dra. Libby Saavedra"
            title="Médico Veterinario Independiente"
          >
            <>
              Hace un tiempo llevaba buscando una aplicación para manejar las
              fichas de mis pacientes y una colega me recomendó Veti. La
              atención fue muy buena, rápida y personalizada. Probé la app y me
              gustó mucho, es muy práctica y fácil de usar, puedes acceder desde
              el celular, tablet o computador, ayuda a tener un orden, registros
              y recordatorios para nosotros y para los tutores y{" "}
              <strong className="font-bold">
                algo maravilloso es el feedback que se tiene con los
                administradores, para una mejora constante del programa.
              </strong>{" "}
              Inversión totalmente recomendable.
            </>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image="testimonial-10"
            name="Dra. Macarena Dominguez"
            title="Médico Veterinario Independiente"
          >
            <>
              Hace tiempo estaba buscando una aplicación para organizar las
              fichas de mis pacientes y organizarme con los recordatorios de
              controles y vacunas, y así cumplir lo mejor posible con mis
              queridos pacientes.{" "}
              <strong className="font-bold">Veti es muy fácil de usar</strong> y
              funciona perfecto desde cualquier celular, iPad o computador. Lo
              mejor de todo es la atención amable, rápida y personalizada para
              resolver las dudas.{" "}
              <strong className="font-bold">Lo recomiendo 100%.</strong>
            </>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image="testimonial-5"
            name="Dr. Carlos Alburquenque"
            title="Clínica Veterinaria Hammurabi"
          >
            <>
              Veti nos ha ayudado a organizar de mejor manera a nuestros
              pacientes, es fácil de usar y muy completa.{" "}
              <strong className="font-bold">
                Nos permite tener una mejor experiencia con los dueños para una
                completa atención
              </strong>
              . Recomendamos esta aplicación para el resto de nuestros colegas
              ya que es fácil y práctica para la organización.{" "}
              <strong className="font-bold">Muy recomendado</strong>.
            </>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image="testimonial-6"
            name="Dra. Fernanda Díaz"
            title="Unidad Móvil Veterinaria Medpets"
          >
            <>
              <strong className="font-bold">Me ha simplificado la vida.</strong>{" "}
              Trabajar en terreno requiere de rapidez y cada tiempo es valioso,
              y esta app me ha permitido ser más rápida y eficiente, que
              escribir todo en una ficha manual.{" "}
              <strong className="font-bold">
                Es ideal para empresas como la mía! Los recomiendo 100%.
              </strong>
            </>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image="testimonial-7"
            name="Dra. Soledad Araya"
            title="Kulliñ Pet"
          >
            <>
              Durante un tiempo buscaba una aplicación que fuese{" "}
              <strong className="font-bold">sencilla y práctica</strong> al
              momento de utilizarla. Veti se adaptó totalmente a los
              requerimientos, ya que me permite ingresar desde mi computadora o
              mi celular, pudiendo agendar pacientes en cualquier momento.{" "}
              <strong className="font-bold">
                El equipo muy comprometido a resolver todas mis dudas e
                inquietudes. 100% recomendable.
              </strong>
            </>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image="testimonial-8"
            name="Dr. Ronald Rojas"
            title="Médico Veterinario Independiente"
          >
            <>
              Llevo poco más de 1 mes usando Veti y la verdad ha sido una{" "}
              <strong className="font-bold">
                muy buena experiencia e inversión
              </strong>
              , es muy simple pero a la vez completa y me permite acceder a la
              información clínica de mis pacientes y tutores de manera muy
              fácil, y lo mejor es que el equipo de soporte siempre está atento
              a ayudarme ante cualquier cosa y constantemente están mejorando y
              actualizándola.{" "}
              <strong className="font-bold">¡Recomendada Totalmente!</strong>
            </>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image="testimonial-9"
            name="Dr. Javier Espejo"
            title="Clínica Veterinaria Tierras Blancas"
          >
            <>
              Nuestra experiencia ha sido bastante buena,{" "}
              <strong className="font-bold">
                muy sencilla y fácil de ocupar
              </strong>{" "}
              tanto en clínica como en domicilios. Nos ha proporcionado una
              mejor información y comportamiento de los clientes.{" "}
              <strong className="font-bold">
                Recomendamos su uso para nuestros colegas.
              </strong>
            </>
          </Testimonial>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
