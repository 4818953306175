import { Geolocation } from "@veti/website-types";
import { useEffect, useReducer } from "react";

import { ACCOUNTS_URL, SETS_URL } from "../../config";
import { useLocation } from "../../providers/LocationContext";
import { UFEntry, AccountPlan, AccountPlanPricingExt } from "../../types";

import { fallbackPricing } from "./fallbackPricing";
import { Pricing } from "./Pricing";

interface PricingState {
  location: Geolocation | undefined;
  plansAndPricings: Array<AccountPlan | AccountPlanPricingExt>;
  plans: Array<AccountPlan> | undefined;
  pricings: Array<AccountPlanPricingExt> | undefined;
  UFEntry: UFEntry | undefined;
}

type reducerActions = {
  type:
    | "setPlansAndPricings"
    | "setLocation"
    | "setUF"
    | "parsePricings"
    | "convertToCLP";
  location?: Geolocation;
  plansAndPricings?: Array<AccountPlan | AccountPlanPricingExt>;
  UFEntry?: UFEntry;
};

const initialState: PricingState = {
  location: undefined,
  plansAndPricings: fallbackPricing,
  plans: undefined,
  pricings: undefined,
  UFEntry: undefined,
};

const filterPricingByCountry = (
  countryCode: string,
  pricings: AccountPlanPricingExt[]
): AccountPlanPricingExt[] => {
  return pricings.filter((pricing) => pricing.country === countryCode);
};

const reducer = (state: PricingState, action: reducerActions): PricingState => {
  let locatedPricing: AccountPlanPricingExt[] = [];

  switch (action.type) {
    case "setLocation":
      return {
        ...state,
        location: action.location,
      };
    case "setPlansAndPricings":
      return {
        ...state,
        plansAndPricings: action.plansAndPricings ?? fallbackPricing,
      };
    case "setUF":
      return {
        ...state,
        UFEntry: action.UFEntry,
      };
    case "parsePricings":
      state.plans = state.plansAndPricings.filter(
        (p): p is AccountPlan => p.type === "plan"
      );

      if (state.location) {
        locatedPricing = filterPricingByCountry(
          state.location.countryCode,
          state.plansAndPricings.filter(
            (pp): pp is AccountPlanPricingExt => pp.type === "plan_pricing"
          )
        );
      }

      return {
        ...state,
        pricings:
          locatedPricing.length > 0
            ? locatedPricing
            : fallbackPricing.filter(
                (pp): pp is AccountPlanPricingExt => pp.type === "plan_pricing"
              ),
      };
    case "convertToCLP":
      if (state.UFEntry && state.pricings) {
        const UFRate = parseInt(state.UFEntry.value);
        return {
          ...state,
          pricings: state.pricings.map((pricing) => {
            const newPrice = Math.floor(pricing.basePrice * UFRate);
            let priceNote = `${pricing.basePrice} UF`;
            priceNote += pricing.basePriceIncludesVAT
              ? ""
              : ` +${pricing.VATName}`;
            return {
              ...pricing,
              currency: "CLP",
              basePrice: newPrice,
              priceNote,
            };
          }),
        };
      }

      return state;
    default:
      throw new Error("No action recognized");
  }
};

export const PricingContainer = () => {
  const { getLocation } = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // this promise chain guarantees the exact execution order
    let loc: Geolocation | undefined = undefined;
    getLocation()
      .then((location) => {
        loc = location;
        dispatch({ type: "setLocation", location });
        return fetch(`${ACCOUNTS_URL}/plans`);
      })
      .then((response) => response.json())
      .then((pp) =>
        dispatch({ type: "setPlansAndPricings", plansAndPricings: pp })
      )
      .then(() => dispatch({ type: "parsePricings" }))
      .then(() => {
        if (loc && loc.countryCode === "CL") {
          return fetch(`${SETS_URL}/UF`);
        } else {
          return undefined;
        }
      })
      .then((response) => (response ? response.json() : undefined))
      .then((UFEntry) => {
        if (UFEntry) {
          dispatch({ type: "setUF", UFEntry });
          dispatch({ type: "convertToCLP" });
        }
      })
      .catch((error) => console.log(error));
  }, [getLocation]);

  return <Pricing plans={state.plans} pricings={state.pricings} />;
};
