import { ExclamationCircleIcon } from "@heroicons/react/solid";

import { classNames } from "../../helpers";

export const TextFormField = ({
  name,
  caption,
  value,
  autocomplete,
  error,
  enabled,
  onChange,
  placeholder = "",
}: {
  name: string;
  caption: string;
  value: string;
  autocomplete: string;
  error: string | null;
  enabled: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  placeholder?: string;
}) => {
  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {caption}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          type="text"
          value={value}
          onChange={onChange}
          name={name}
          autoComplete={autocomplete}
          disabled={!enabled}
          placeholder={placeholder}
          className={classNames(
            error
              ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
              : !enabled
              ? "border-gray-300 text-gray-300 placeholder-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500"
              : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300",
            "block w-full mt-1 pr-10 shadow-sm sm:text-sm rounded-md"
          )}
        />

        {error ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {error ? (
        <p className="mt-2 text-xs text-red-600" id="email-error">
          {error}
        </p>
      ) : (
        ""
      )}
    </>
  );
};
