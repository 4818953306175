import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";

import { DynamicHeroIcon } from "../components/DynamicHeroIcon";

interface NavbarProps {
  pricingRef: React.MutableRefObject<HTMLDivElement | null>;
  demoFormRef: React.MutableRefObject<HTMLDivElement | null>;
  featuresRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const Navbar = ({
  pricingRef,
  demoFormRef,
  featuresRef,
}: NavbarProps) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [currentRef, setCurrentRef] =
    useState<React.MutableRefObject<HTMLDivElement | null>>();

  const toggleMenu = () => {
    setMenuVisible((visible) => !visible);
  };

  return (
    <div className="flex space-x-6 text-gray-600">
      <div className="flex-1">
        <img src="logo.png" alt="Veti Logo" />
      </div>
      <div className="hidden space-x-6 md:flex">
        <div className="my-auto">
          <a href="#pricing">Planes</a>
        </div>
        <div className="my-auto">
          <a href="#features">Características</a>
        </div>
        <div className="my-auto">
          <a href="#demo-form">Solicita tu demo</a>
        </div>
        <div>
          <a
            className="inline-block px-3 py-2 rounded-md bg-gray-200 hover:bg-gray-100 focus:ring-gray-500 focus:ring-opacity-50 focus:outline-none focus:ring focus:ring-offset-2 active:bg-gray-600 tracking-wider font-semibold text-sm text-gray-700 shadow-lg sm:text-base"
            href="https://dashboard.veti.app"
          >
            Iniciar sesión
          </a>
        </div>
      </div>
      <div className="flex md:hidden">
        <button className="w-8 h-8" onClick={toggleMenu}>
          <DynamicHeroIcon icon="MenuIcon" />
        </button>
        <Transition.Root show={menuVisible} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={toggleMenu}
          >
            <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-90 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-top sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                afterLeave={() => {
                  if (
                    currentRef &&
                    currentRef.current instanceof HTMLDivElement
                  ) {
                    currentRef.current.scrollIntoView();
                    setCurrentRef(undefined);
                  }
                }}
              >
                <div className="relative w-screen inline-block align-top bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:p-6">
                  <div>
                    <button
                      className="absolute right-4 top-4 w-5 h-5 text-gray-400"
                      onClick={toggleMenu}
                    >
                      <DynamicHeroIcon icon="XIcon" />
                    </button>
                    <div className="mt-3 text-center sm:mt-5">
                      <img
                        src="logo.png"
                        alt="Veti Logo"
                        className="mx-auto my-10"
                      />
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-600 mt-20"
                        onClick={() => {
                          setCurrentRef(pricingRef);
                          toggleMenu();
                        }}
                      >
                        <button>Planes</button>
                      </Dialog.Title>
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-600 mt-5"
                        onClick={() => {
                          setCurrentRef(featuresRef);
                          toggleMenu();
                        }}
                      >
                        <button>Características</button>
                      </Dialog.Title>
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-600 mt-5 mb-20"
                        onClick={() => {
                          setCurrentRef(demoFormRef);
                          toggleMenu();
                        }}
                      >
                        <button>Solicita tu demo</button>
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <a
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-500 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      href="https://dashboard.veti.app"
                    >
                      Inicia sesión
                    </a>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
};
