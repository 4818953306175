import { IfDemoForm } from "../../types";

const URL = "https://karmasian15557.activehosted.com/proc.php";
const acDemoFormExtraParams =
  "u=4&f=4&s=&c=0&m=0&act=sub&v=2&or=43d40fd20cb52af686afc2a229971d66&jsonp=true";
const acNewsletterFormExtraParams = "u=3&f=3&s=&c=0&m=0&act=sub&v=2&jsonp=true";

export const sendDemoForm = (formData: IfDemoForm): Promise<string> => {
  const serializedData = serialize(formData);
  const demoControllerURL = `${URL}?${serializedData}&${acDemoFormExtraParams}`;
  const newsletterControllerURL = `${URL}?${serializedData}&${acNewsletterFormExtraParams}`;
  return new Promise((resolve, reject) => {
    fetch(demoControllerURL, { mode: "no-cors" })
      .then((response) => {
        // @ts-ignore
        fbq("track", "StartTrial", {
          value: 13000,
          currency: "clp",
          subscription_id: "plan_basico",
        });
        // @ts-ignore
        gtag_report_conversion();
        if (formData.fields.newsletter) {
          fetch(newsletterControllerURL, { mode: "no-cors" });
        }
        return resolve("OK");
        // This when implementing lambda intermediary
        // if (response.ok) {
        //   resolve("ok");
        // } else {
        //   reject("not ok");
        // }
      })
      .catch((error) => reject(error));
  });
};

const serialize = (formData: IfDemoForm): string => {
  const formFields = formData.fields;
  const querySet: string[] = [];
  for (const prop in formFields) {
    const fieldName = formFields[prop].activeCampaignFieldValue;
    const fieldValue = formFields[prop].value;
    if (fieldName !== undefined) {
      switch (typeof fieldValue) {
        case "string":
          querySet.push(`${fieldName}=${encodeURIComponent(fieldValue)}`);
          break;
        case "object":
          querySet.push(`${fieldName}=${encodeURIComponent(fieldValue.name)}`);
          break;
        default:
          break;
      }
    }
  }
  return querySet.join("&");
};
