import { useRef } from "react";

import { LocationProvider } from "../providers/LocationContext";
import { DemoForm } from "../sections/DemoForm/DemoForm";
import { Features } from "../sections/Features";
import { Footer } from "../sections/Footer";
import { Hero } from "../sections/Hero";
import { Navbar } from "../sections/Navbar";
import { PricingContainer } from "../sections/Pricing/PricingContainer";
import { Scheduler } from "../sections/Scheduler";
import { Testimonials } from "../sections/Testimonials/Testimonials";
import { Timeline } from "../sections/Timeline";
import { WhatsappButton } from "../sections/WhatsappButton";

function SinglePageWebsite() {
  const pricingRef = useRef(null);
  const demoFormRef = useRef(null);
  const featuresRef = useRef(null);

  return (
    <LocationProvider>
      <>
        <div className="container mx-auto p-10">
          <Navbar
            pricingRef={pricingRef}
            demoFormRef={demoFormRef}
            featuresRef={featuresRef}
          />
          <Hero />
          <div id="features" ref={featuresRef}>
            <Features />
          </div>

          <Timeline />
          <Scheduler />
          <Testimonials />
          <div id="pricing" ref={pricingRef}>
            <PricingContainer />
          </div>
          <div id="demo-form" ref={demoFormRef}>
            <DemoForm />
          </div>
          <WhatsappButton />
        </div>
        <Footer />
      </>
    </LocationProvider>
  );
}

export default SinglePageWebsite;
