import { CheckIcon } from "@heroicons/react/outline";
import { useEffect, useState, useReducer, useRef } from "react";

import {
  classNames,
  isEmailValid,
  isNameValid,
  isNameValidEmpty,
  isPhoneValid,
} from "../../helpers";
import { Country, IfDemoForm } from "../../types";

import ErrorDialog from "./ErrorDialog";
import { sendDemoForm } from "./sendDemoForm";
import { TextFormField } from "./TextFormField";

const initialState: IfDemoForm = {
  sendFn: undefined,
  isFormValid: false,
  sending: false,
  success: null,
  fields: {
    firstName: {
      value: "",
      error: "Por favor introduce un nombre válido",
      isValid: true,
      validator: isNameValid,
      activeCampaignFieldValue: "firstname",
    },
    lastName: {
      value: "",
      error: "Por favor introduce un apellido válido",
      isValid: true,
      validator: isNameValid,
      activeCampaignFieldValue: "lastname",
    },
    email: {
      value: "",
      error: "Por favor introduce un email válido",
      isValid: true,
      validator: isEmailValid,
      activeCampaignFieldValue: "email",
    },
    phone: {
      value: "",
      error: "Por favor introduce un número válido",
      isValid: true,
      validator: isPhoneValid,
      activeCampaignFieldValue: "phone",
    },
    country: {
      value: { code: "AR", name: "Argentina" },
      error: null,
      isValid: true,
      activeCampaignFieldValue: "field[7]",
    },
    city: {
      value: "",
      error: "Por favor introduce una ciudad válida",
      isValid: true,
      validator: isNameValid,
      activeCampaignFieldValue: "field[6]",
    },
    company: {
      value: "",
      error: "Por favor introduce un nombre de empresa válida",
      isValid: true,
      validator: isNameValidEmpty,
      activeCampaignFieldValue: "customer_account",
    },
    newsletter: { value: true, error: null, isValid: true },
  },
};

interface DemoFormReducerAction {
  type:
    | "setField"
    | "submit"
    | "sendForm"
    | "success"
    | "failure"
    | "closeDialog";
  fieldName: string;
  value: string | boolean;
}

const countries: Country[] = [
  { code: "AR", name: "Argentina" },
  { code: "BO", name: "Bolivia" },
  { code: "CL", name: "Chile" },
  { code: "CO", name: "Colombia" },
  { code: "EC", name: "Ecuador" },
  { code: "PE", name: "Perú" },
];

const reducer = (
  state: IfDemoForm,
  action: DemoFormReducerAction
): IfDemoForm => {
  let isFormValid = true;
  switch (action.type) {
    case "setField":
      if (action.fieldName === "country") {
        return {
          ...state,
          fields: {
            ...state.fields,
            country: {
              ...state.fields.country,
              value: countries.filter(
                (country) => country.code === action.value
              )[0],
            },
          },
        };
      } else {
        return {
          ...state,
          fields: {
            ...state.fields,
            [action.fieldName]: {
              ...state.fields[action.fieldName],
              value: action.value,
              isValid: true,
            },
          },
        };
      }
    case "submit":
      for (const prop in state.fields) {
        if (state.fields[prop].validator) {
          const isFieldValid = state.fields[prop].validator?.call(
            undefined,
            state.fields[prop].value as string
          ) as boolean;
          state.fields[prop].isValid = isFieldValid;
          isFormValid = isFormValid && isFieldValid;
        }
      }

      return {
        ...state,
        isFormValid: isFormValid,
        sending: isFormValid,
        sendFn: isFormValid ? () => sendDemoForm(state) : undefined,
      };
    case "success":
      return { ...state, sending: false, sendFn: undefined, success: true };
    case "failure":
      return { ...state, sending: false, sendFn: undefined, success: false };
    case "closeDialog":
      return { ...state, sending: false, sendFn: undefined, success: null };

    default:
      throw new Error("Reducer action not recognized");
  }
};

export const DemoForm = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [leftOffset, setLeftOffset] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (state.sendFn) {
      state
        .sendFn()
        .then(() => dispatch({ type: "success", fieldName: "", value: "" }))
        .catch(() => dispatch({ type: "failure", fieldName: "", value: "" }));
    }
  }, [state]);

  useEffect(() => {
    if (containerRef.current) {
      setLeftOffset(containerRef.current.offsetLeft);
    }
  }, []);

  window.addEventListener("resize", () => {
    if (containerRef.current) {
      setLeftOffset(containerRef.current.offsetLeft);
    }
  });

  return (
    <div className="mt-20 sm:mt-14 relative" ref={containerRef}>
      <h2 className="text-4xl text-gray-800 font-bold lg:pr-7 text-center">
        Solicita tu demo
      </h2>
      <p className="mt-1 text-sm text-gray-600 text-center">
        Prueba Veti{" "}
        <span className="font-bold text-indigo-500">gratis por 15 días</span>.
        No se require tarjeta de crédito.
      </p>
      <div className="max-w-screen-sm mx-auto mt-10">
        <div className="shadow overflow-hidden sm:rounded-md">
          {state.success === true ? (
            <div className="px-4 py-5 bg-white bg-opacity-90 sm:p-6">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <CheckIcon
                  className="h-6 w-6 text-green-600"
                  aria-hidden="true"
                />
              </div>
              <h2 className="font-semibold text-xl text-center pt-6 text-gray-700">
                ¡Gracias por darnos una oportunidad!
              </h2>
              <p className="text-gray-500 text-sm text-center py-10 px-10">
                A la brevedad enviaremos a tu correo las instrucciones para
                poder acceder a Veti. Además nuestro equipo se contactará
                contigo para programar una visita guiada y mostrárte lo sencillo
                que es utilizar nuestra aplicación.
              </p>
              <p className="text-center pb-6">
                <span
                  role="img"
                  aria-label="Manos celebrando"
                  className="text-6xl"
                >
                  🙌
                </span>
              </p>
            </div>
          ) : (
            <>
              <div className="px-4 py-5 bg-white sm:p-6 bg-opacity-90">
                <div className="grid grid-cols-6 gap-6 ">
                  <div className="col-span-6 sm:col-span-3">
                    <TextFormField
                      name="firstName"
                      value={state.fields.firstName.value}
                      error={
                        state.fields.firstName.isValid
                          ? null
                          : state.fields.firstName.error
                      }
                      caption="Nombre"
                      autocomplete="given-name"
                      enabled={!state.sending}
                      onChange={(e) =>
                        dispatch({
                          type: "setField",
                          fieldName: "firstName",
                          value: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <TextFormField
                      name="lastName"
                      value={state.fields.lastName.value}
                      error={
                        state.fields.lastName.isValid
                          ? null
                          : state.fields.lastName.error
                      }
                      caption="Apellido"
                      autocomplete="family-name"
                      enabled={!state.sending}
                      onChange={(e) =>
                        dispatch({
                          type: "setField",
                          fieldName: "lastName",
                          value: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <TextFormField
                      name="email"
                      value={state.fields.email.value}
                      error={
                        state.fields.email.isValid
                          ? null
                          : state.fields.email.error
                      }
                      caption="Correo electrónico"
                      autocomplete="email"
                      enabled={!state.sending}
                      onChange={(e) =>
                        dispatch({
                          type: "setField",
                          fieldName: "email",
                          value: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <TextFormField
                      name="phone"
                      value={state.fields.phone.value}
                      error={
                        state.fields.phone.isValid
                          ? null
                          : state.fields.phone.error
                      }
                      caption="Teléfono"
                      autocomplete="tel"
                      enabled={!state.sending}
                      placeholder="Ej.: +5698690XXXX"
                      onChange={(e) =>
                        dispatch({
                          type: "setField",
                          fieldName: "phone",
                          value: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pb-1"
                    >
                      País
                    </label>
                    <select
                      id="country"
                      name="country"
                      autoComplete="country-name"
                      disabled={state.sending}
                      onChange={(evt) =>
                        dispatch({
                          type: "setField",
                          fieldName: "country",
                          value: evt.target.value,
                        })
                      }
                      className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                      {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <TextFormField
                      name="city"
                      value={state.fields.city.value}
                      error={
                        state.fields.city.isValid
                          ? null
                          : state.fields.city.error
                      }
                      caption="Ciudad"
                      autocomplete="address-level2"
                      enabled={!state.sending}
                      onChange={(e) =>
                        dispatch({
                          type: "setField",
                          fieldName: "city",
                          value: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-span-6">
                    <TextFormField
                      name="company"
                      value={state.fields.company.value}
                      error={
                        state.fields.company.isValid
                          ? null
                          : state.fields.company.error
                      }
                      caption="Empresa (opcional)"
                      autocomplete="organization"
                      enabled={!state.sending}
                      onChange={(e) =>
                        dispatch({
                          type: "setField",
                          fieldName: "company",
                          value: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-span-6">
                    <div className="relative flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          type="checkbox"
                          checked={state.fields.newsletter.value}
                          onChange={(e) =>
                            dispatch({
                              type: "setField",
                              fieldName: "newsletter",
                              value: e.target.checked,
                            })
                          }
                          name="newsletter"
                          disabled={state.sending}
                          className={classNames(
                            state.sending
                              ? "focus:ring-gray-200  text-gray-300 border-gray-200"
                              : "focus:ring-indigo-500  text-indigo-500 border-gray-300",
                            "rounded h-4 w-4"
                          )}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="newsletter"
                          className="font-medium text-gray-700"
                        >
                          Quiero mantenerme informado
                        </label>
                        <p id="comments-description" className="text-gray-500">
                          Recibe información de actualizaciones, nuevas
                          características, noticias y más.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="submit"
                  disabled={state.sending}
                  onClick={() =>
                    dispatch({ type: "submit", fieldName: "", value: "" })
                  }
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full sm:w-auto"
                >
                  {state.sending ? "Enviando..." : "Solicita tu demo gratis"}
                </button>
              </div>
              {state.success === false ? (
                <ErrorDialog
                  onClose={() =>
                    dispatch({ type: "closeDialog", fieldName: "", value: "" })
                  }
                />
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
      <div
        className="-z-1 transform -skew-y-12 absolute top-80"
        style={{ marginLeft: `-${leftOffset}px` }}
      >
        <div className="absolute w-screen h-10 opacity-95 max-h-screen bg-indigo-500"></div>
        <div className="absolute w-56 h-10 left-5 -top-7 bg-gradient-to-tr opacity-95 max-h-screen bg-yellow-300"></div>
      </div>
    </div>
  );
};
