import React from "react";

import { TermsHeader } from "../sections/TermsHeader";

function TermsAndConditions() {
  return (
    <div className="container mx-auto py-10 px-10 lg:px-56">
      <div>
        <img src="logo.png" alt="Veti Logo" className="mx-auto" />
      </div>

      <h1 className="text-3xl text-gray-700 my-7 mt-10 font-bold lg:text-4xl text-center">
        T&eacute;rminos y Condiciones Veti.app
      </h1>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Los t&eacute;rminos y condiciones expresados en este contrato
        constituyen las condiciones de uso y privacidad para todos los servicios
        prestados en la actualidad y los a&ntilde;adidos en el futuro, por Veti,
        software veterinario representado por la empresa Gritlance SpA., en su
        sitio web www.veti.app, su aplicaci&oacute;n dashboard.veti.app, la
        versi&oacute;n m&oacute;vil Veti y Api.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        El cliente del servicio Veti acepta por simple causal de uso de la
        aplicaci&oacute;n, lo dispuesto en este contrato y en sus
        T&eacute;rminos y Condiciones, susceptible de cambio sin previo aviso
        por parte de Veti. Si el cliente representa una organizaci&oacute;n,
        est&aacute; dando por entendido que la organizaci&oacute;n acepta
        ce&ntilde;irse a este contrato y que tiene las facultades para actuar en
        nombre de aquella y por lo tanto obligarla frente a Veti a aceptar las
        obligaciones establecidas en el presente contrato. El cliente que no
        est&eacute; de acuerdo con esto, no podr&aacute; hacer uso de los
        servicios prestados por Veti.
      </p>
      <h2 className="text-xl text-gray-700 my-7 font-bold lg:text-2xl">
        Servicio Contratado
      </h2>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Veti presta un servicio de software al que se accede a trav&eacute;s de
        su sitio web www.veti.app. Los clientes que accedan a este servicio
        deber&aacute;n registrar una cuenta y brindar la informaci&oacute;n
        solicitada en los formularios que se habilitan a la hora de registrar
        una cuenta. Veti da por entendido que cualquier informaci&oacute;n
        ingresada en estos formularios es hecha bajo juramento y por lo tanto
        exonera a Veti de poseer informaci&oacute;n falsa sobre cualquier
        cliente.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Veti permite el ingreso de una cantidad limitada de usuarios
        seg&uacute;n el plan que cada cliente titular haya elegido al momento de
        registrar su cuenta. Veti no permite, bajo ninguna circunstancia, que
        estos usuarios sean distintos de aquellos que el cliente titular de la
        cuenta haya decidido habilitar como tales para el uso de la plataforma y
        estos no podr&aacute;n ser reemplazados por otras personas que conozcan
        las credenciales para ingresar a la plataforma. De suceder una
        situaci&oacute;n como &eacute;sta, Veti no se hace responsable por el
        uso pernicioso de la informaci&oacute;n de la cuenta en contra del
        cliente titular de la misma ni frente al usuario ni frente a terceros.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        El servicio se inicia al momento de registrar una cuenta en el sitio web
        de Veti, tras haber aceptado las condiciones expresadas en este
        contrato. El servicio consiste en el uso del software Veti disponible en
        dashboard.veti.app, aplicaciones m&oacute;viles y Api.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        El cliente se hace conocedor de los servicios por los que est&aacute;
        pagando a la hora de usar alguno de los planes que ofrece Veti. Veti no
        se har&aacute; responsable en ning&uacute;n caso por los errores
        cometidos por el cliente a la hora de elegir su plan, as&iacute; como
        tampoco al momento de digitar o ingresar su informaci&oacute;n tanto
        personal como de la operaci&oacute;n y marcha de su actividad
        empresarial ni tampoco de la clasificaci&oacute;n que realice de la
        misma lo que afectar&aacute; irremediablemente los resultados arrojados
        por el Software.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Veti da por entendido que el cliente conoce cualquier cambio que se haga
        en la configuraci&oacute;n de &eacute;sta, as&iacute; como que
        realizar&aacute; los cambios que considere pertinentes.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        En condiciones normales, la plataforma de Veti tiene un &ldquo;down
        time&rdquo; de aproximadamente 1%, de modo que la plataforma se
        encuentra disponible el 99% del tiempo. Veti llevar&aacute; a cabo las
        gestiones que a su juicio estime conducentes, tendientes a que los
        sitios webs asociados siempre est&eacute;n disponibles para el cliente,
        pero no garantiza lo anterior, tanto por los da&ntilde;os en las
        comunicaciones, por actos de terceros, mantenimiento o
        reestructuraci&oacute;n de los sitios u otro tipo de actos que se
        escapen del alcance y responsabilidad directa de Veti. No obstante lo
        anterior, Veti garantiza que el software estar&aacute; disponible en
        dashboard.veti.app desde cualquier lugar del mundo que permita una
        conexi&oacute;n a internet, siempre y cuando el cliente recuerde las
        credenciales de su cuenta y las condiciones de prestaci&oacute;n del
        servicio de conexi&oacute;n a Internet por parte de cada proveedor, lo
        permitan, lo cual escapa de las obligaciones de Veti, de manera tal que
        si no se pudiera acceder por el tipo de conexi&oacute;n y las
        caracter&iacute;sticas que le sean propias, por fallas en la
        comunicaci&oacute;n o por el deficiente servicio del mencionado
        proveedor, ello no implica en ning&uacute;n momento incumplimiento de la
        prestaci&oacute;n del servicio contratado por parte de Veti.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        En cuanto al mantenimiento y reestructuraci&oacute;n del sitio, Veti se
        reserva el derecho de hacerlo sin previo aviso a los usuarios, pero
        procurar&aacute; que se les brinde un aviso con la antelaci&oacute;n que
        a su juicio considere conveniente o prudente para evitar incomodidades o
        eventuales perjuicios en el procesamiento de datos o de
        informaci&oacute;n.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Veti es una plataforma online de gesti&oacute;n veterinaria, que
        adem&aacute;s posee soporte remoto, disponible de 9 a 18 hrs hora local
        Chile. El soporte es en cualquiera de los canales disponibles
        (tel&eacute;fono o email). Las capacitaciones son gratuitas a
        trav&eacute;s de video llamadas.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        La carga de datos no est&aacute; incluida en el pago de la
        suscripci&oacute;n. Cualquier carga de datos externos debe ser evaluada
        en t&eacute;rminos de consumo de horas de trabajo, con un valor de 60
        USD (1,5 UF) la hora. Adem&aacute;s, se se&ntilde;ala de manera
        expl&iacute;cita, que los datos del cliente a cargar deben pertenecer a
        la cl&iacute;nica o doctor solicitante, y no puede tratarse de una Base
        de Datos adquirida. Para resguardar la veracidad de esta
        informaci&oacute;n, ser&aacute; requisito excluyente que &eacute;sta
        cuente con el n&uacute;mero de identificaci&oacute;n nacional de cada
        due&ntilde;o de los pacientes (mascotas).
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        El espacio inicial para almacenamiento de im&aacute;genes es de 10 Gb.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Existe la posibilidad que un cliente pueda solicitar ampliar la
        capacidad, independiente del plan. Por cada 5 Gb extra, se
        cobrar&aacute; un monto de 25 USD/mes (0,5 UF/mes) adicionales al valor
        del plan.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Veti posee la facultad de modificar la tarifa mensual cobrada por el
        servicio una vez al a&ntilde;o, por t&eacute;rminos de reajuste. Esta
        tarifa no puede subir m&aacute;s de un 10% anual, y su objetivo es,
        entre otros, compensar los efectos inflacionarios. En caso de que Veti
        desee modificar la tarifa, se le notificar&aacute; al cliente al menos
        con un mes de anticipaci&oacute;n, para que pueda recuperar su
        informaci&oacute;n y salir del servicio si lo desea.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        El cliente acepta que su pago no est&aacute; sujeto a la entrega de
        cualquier funcionalidad o caracter&iacute;sticas futuras, a menos que
        Veti as&iacute; lo comunique.
      </p>
      <h2 className="text-xl text-gray-700 my-7 font-bold lg:text-2xl">
        Tarifas y Pago
      </h2>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        El cliente podr&aacute; contratar el pago autom&aacute;tico del Servicio
        &ldquo;Veti&reg;&rdquo; a trav&eacute;s del sistema OneClick, operado
        por Transbank S.A.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        La modalidad de cobro OneClick permite al cliente agregar una tarjeta de
        cr&eacute;dito o d&eacute;bito, en la cual autoriza a Veti (representado
        por Gritlance SpA) a cargar mensual o anualmente el valor en UF
        correspondiente al servicio contratado, por lo cual el cliente se
        compromete a mantener la tarjeta vigente y con el cr&eacute;dito
        suficiente para cubrir los cargos que efect&uacute;e la
        compa&ntilde;&iacute;a. En caso de no poder efectuar el cargo en la
        tarjeta de cr&eacute;dito o d&eacute;bito, el cobro se
        reintentar&aacute; durante los 3 d&iacute;as siguientes, si durante los
        4 intentos de cobro &eacute;ste resulta infructuoso y usted no contacta
        al equipo de pagos de Veti, su plataforma quedar&aacute; en estado
        impago e ingresar&aacute; en el proceso de corte autom&aacute;tico.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Veti protege y asegura los datos personales y de la tarjeta de
        cr&eacute;dito o d&eacute;bito asociada al servicio y en dicho
        m&eacute;rito garantiza que ser&aacute;n utilizados solo para los fines
        asociados a la prestaci&oacute;n del servicio contratado y no los
        revelar&aacute; a terceros, a menos que estemos requeridos por ley o
        autoridad a hacerlo, dando cumplimiento a las normas legales y
        disposiciones reglamentarias de protecci&oacute;n de datos personales.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        El cliente podr&aacute; elegir el plan y periodo de pago al momento de
        realizar el primer pago. Si el per&iacute;odo elegido es mensual, el
        cobro se realizar&aacute; los primeros d&iacute;as del mes en que se
        inscribi&oacute;. Si se inscribe despu&eacute;s del d&iacute;a 10, el
        primer cobro ser&aacute; los primeros d&iacute;as del mes siguiente. Si
        el per&iacute;odo elegido es anual, el cobro se realizar&aacute; el
        mismo d&iacute;a que se inscribi&oacute;, y se repetir&aacute; el mismo
        d&iacute;a de cada a&ntilde;o siguiente.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        El pago del servicio otorgado por Veti es anticipado y el cliente acepta
        que se realicen cobros recurrentes seg&uacute;n los periodos elegidos.
        Adem&aacute;s se compromete a notificar a Veti en caso tal de que no
        quiera que se genere el siguiente cobro, m&iacute;nimo 2 d&iacute;as
        h&aacute;biles antes de su pr&oacute;xima fecha de cobro.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Veti asume que la informaci&oacute;n de la tarjeta de cr&eacute;dito o
        d&eacute;bito ingresada para crear una cuenta es conocida s&oacute;lo
        por el titular de la cuenta, por lo tanto, no se hace responsable por el
        uso inadecuado de tarjetas de cr&eacute;dito al momento de esta ser
        usada para registrar una cuenta, entendi&eacute;ndose como uso
        irresponsable, la falsificaci&oacute;n y suplantaci&oacute;n de la
        identidad del titular la cuenta.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Veti, por lo tanto, se abstiene de llevar a cabo todo tipo de actos
        tendientes a llevar a cabo la verificaci&oacute;n de los datos
        personales del cliente para confrontarlos con los registrados en su
        tarjeta de cr&eacute;dito o d&eacute;bito pues asume que quien brinde
        los mismos es su propio titular y por ello conoce datos a los que no
        tiene acceso cualquier persona, adem&aacute;s de no tener bases de datos
        con las cuales confrontar tal informaci&oacute;n.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        No obstante esto, el cliente autoriza a Veti a llevar a cabo cualquier
        verificaci&oacute;n o consulta de los datos suyos en cualquier base de
        datos, centrales de informaci&oacute;n y riesgo y cualquier otra fuente
        de informaci&oacute;n que le brinde historia sobre la forma en la que
        atiende sus compromisos financieros y crediticios, tanto en el
        &aacute;mbito nacional como en el internacional.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Las tarifas se basan en servicios y contenidos adquiridos y no el uso
        real. Las obligaciones de pago son &ldquo;no cancelables&rdquo; y las
        tarifas pagadas no son reembolsables (a excepci&oacute;n de que si a lo
        largo de un a&ntilde;o completo llegase a proporcionar un nivel de
        servicio inferior al 99%, usted podr&aacute; solicitar la
        devoluci&oacute;n proporcional del dinero de acuerdo a las horas en las
        que no tuvo servicio). La facturaci&oacute;n o pago se realiza por
        per&iacute;odo anticipado (ya sea mensual o anual), por lo cual, al
        emitir la notificaci&oacute;n de cobro del per&iacute;odo no se
        realizar&aacute; devoluci&oacute;n de dinero.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        La notificaci&oacute;n de cobro es emitida y enviada al cliente de forma
        electr&oacute;nica v&iacute;a e-mail o whatsapp, es responsabilidad del
        cliente proporcionar dicha direcci&oacute;n y n&uacute;mero de celular e
        indicar a Veti cualquier cambio, posterior a la activaci&oacute;n de la
        plataforma, de dicha direcci&oacute;n o n&uacute;mero de celular.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        No aplica la devoluci&oacute;n del dinero en aquellos casos en los que
        se contrata la plataforma durante un d&iacute;a por la ma&ntilde;ana y
        se descontrata por la tarde o a los pocos d&iacute;as, ya que eso
        implica que solo se realiz&oacute; la suscripci&oacute;n con el fin de
        hurgar dentro del sistema, sin una intenci&oacute;n real de aprender
        sobre su uso para juzgar si es &uacute;til o no.
      </p>
      <h2 className="text-xl text-gray-700 my-7 font-bold lg:text-2xl">
        Interrupci&oacute;n y Terminaci&oacute;n del Servicio
      </h2>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Veti se reserva el derecho de modificar, suspender o interrumpir el
        Servicio en cualquier momento si el cliente posee deudas de cualquier
        tipo con la entidad. La suspensi&oacute;n de la cuenta del cliente no
        elimina su obligaci&oacute;n de pagar las deudas pendientes, as&iacute;
        como tampoco los gastos de cobranza e indemnizaci&oacute;n de perjuicios
        que se pudiesen generar a favor de Veti por tal incumplimiento.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Veti podr&aacute; terminar de manera unilateral la cuenta de un cliente
        en los siguientes escenarios: (i) En caso de que el cliente utilice los
        servicios prestados por Veti para fines contrarios a la ley,
        especialmente aquellos que contrar&iacute;en derechos de propiedad
        intelectual de terceros y sobretodo de Veti y de otros usuarios; (ii) En
        caso de que Veti encuentre que el cliente est&aacute; haciendo uso de su
        cuenta para la transmisi&oacute;n de programas malignos como virus,
        malwares, spywares, troyanos o similares, que puedan comprometer el
        debido funcionamiento de la plataforma de Veti o que perjudiquen a
        terceros; (iii) Cuando existan elementos que permitan inferir a Veti que
        el cliente no cuenta con las facultades necesarias para contratar los
        Servicios.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Veti se reserva el derecho de modificar, suspender o cancelar su cuenta
        si se descubren comportamientos inapropiados dentro de la plataforma,
        c&oacute;mo intenciones maliciosas, pirater&iacute;a, u otra actividad
        relacionada.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Veti se reserva el derecho a decidir si el contenido publicado por los
        clientes, al igual que el material de texto o fotogr&aacute;fico que sea
        cargado a la p&aacute;gina web de Veti resulta apropiado y se ajusta a
        las Condiciones. En este sentido, Veti podr&aacute; impedir la
        publicaci&oacute;n y comercializaci&oacute;n de contenido que infrinja
        derechos de imagen, de data y de privacidad de terceros, as&iacute; como
        aquellos que resulten ofensivos, difamatorios o que constituyan
        infracciones a la ley.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        En caso de falta de pago, o uso indebido de la cuenta, el cliente
        recibir&aacute; a su correo una notificaci&oacute;n a partir de la cual
        tendr&aacute; un periodo de gracia de 15 d&iacute;as durante el cual
        podr&aacute; consultar su cuenta y la informaci&oacute;n que hasta el
        momento se encuentre almacenada en ella, pero sin la posibilidad de
        agregar nueva informaci&oacute;n. El cliente ser&aacute; responsable de
        copiar o sustraer dicha informaci&oacute;n, por cuanto una vez venza el
        periodo de 15 d&iacute;as, Veti dejar&aacute; de ser responsable de la
        informaci&oacute;n albergada en la cuenta y esta podr&aacute; ser
        eliminada libremente.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Si cualquier cantidad facturada no es recibida por nosotros en la fecha
        prevista, (a) los cargos pueden devengar intereses de mora al tipo del
        1,5% del saldo pendiente por mes, o la tasa m&aacute;xima permitida por
        la ley, lo que sea menor, y/o (b) Podemos condicionar futuras
        renovaciones de la suscripci&oacute;n.
      </p>
      <h2 className="text-xl text-gray-700 my-7 font-bold lg:text-2xl">
        Cancelaci&oacute;n del servicio
      </h2>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        El cliente es el &uacute;nico responsable de la adecuada
        cancelaci&oacute;n de su cuenta. El cliente puede solicitar la
        cancelaci&oacute;n de la suscripci&oacute;n cuando lo desee. El servicio
        quedar&aacute; inactivo el primer d&iacute;a del mes siguiente al que se
        hace la solicitud. Dentro de este plazo el cliente podr&aacute; acceder
        a revisar toda su informaci&oacute;n.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Luego de este plazo, Veti enviar&aacute; v&iacute;a email un archivo en
        formato excel con el respaldo de toda la informaci&oacute;n ingresada en
        el software por parte del cliente.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Despu&eacute;s de 12 semanas desde la cancelaci&oacute;n del servicio,
        Veti borrar&aacute; toda la informaci&oacute;n sin almacenar nada de
        ella, y sin alternativa de recuperarla.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Aquellos clientes que presenten deudas y deseen terminar con el
        servicio, podr&aacute;n solicitar los datos almacenados en la
        plataforma, los que ser&aacute;n entregados una vez que la deuda se
        encuentre normalizada.
      </p>
      <h2 className="text-xl text-gray-700 my-7 font-bold lg:text-2xl">
        Derecho de Autor y Propiedad
      </h2>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        La informaci&oacute;n perteneciente a la base de datos es confidencial y
        de propiedad del cliente, y Veti nunca publicar&aacute; ni har&aacute;
        uso de ella mencionando y comentando puntualmente alguno de estos
        registros. Veti no reclamar&aacute; derechos de propiedad intelectual
        sobre el contenido que el cliente suba o proporcione al Servicio, siendo
        el cliente el &uacute;nico responsable por la informaci&oacute;n y
        contenido ingresado al sistema.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Veti pone en conocimiento de sus clientes, que la informaci&oacute;n que
        suministre se albergar&aacute; en servidores de terceros,
        espec&iacute;ficamente Amazon, que cumplen con los m&aacute;s altos
        est&aacute;ndares de seguridad e idoneidad.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        El cliente de Veti manifiesta ser due&ntilde;o de la informaci&oacute;n
        que ingresa en el sistema y bajo ninguna circunstancia esta
        informaci&oacute;n pasar&aacute; a ser propiedad de Veti, y de manera
        inversa, esta &uacute;ltima sociedad es la &uacute;nica due&ntilde;a de
        la plataforma sobre la cual el cliente ingresa la informaci&oacute;n. El
        cliente no puede copiar, modificar, adaptar, reproducir, distribuir,
        realizar ingenier&iacute;a inversa, descompilar o desensamblar cualquier
        aspecto del servicio de Veti.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Si el cliente da por terminado el contrato tendr&aacute; la
        informaci&oacute;n a su alcance, pero en ning&uacute;n momento Veti se
        obliga a entregar la misma en formato alguno ni a llevar a cabo
        ning&uacute;n tipo de proceso de migraci&oacute;n, ni mucho menos a
        efectuar un desarrollo para que la informaci&oacute;n pueda ser
        analizada, ingresada o digitalizada en cualquier otro software, pues
        s&oacute;lo se ingresan datos, para su consulta y procesamiento en aras
        de su funcionamiento y uso.
      </p>
      <p className="text-gray-500 my-6 text-lg lg:text-base text-justify">
        Adicional a lo anterior, Veti no se responsabiliza por el mal
        diligenciamiento de un formulario o por la informaci&oacute;n que
        err&oacute;neamente suministre el cliente al momento de realizar un
        tr&aacute;mite o de ingresar la informaci&oacute;n al software.
      </p>
    </div>
  );
}

export default TermsAndConditions;
