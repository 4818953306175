import { useState, useEffect, useRef } from "react";

import { DynamicHeroIcon } from "../components/DynamicHeroIcon";

interface TimelineEntryProps {
  icon: string;
  title: string;
  text: string;
  color: string;
}

const TimelineEntry = (props: TimelineEntryProps) => {
  return (
    <div className="flex space-x-4 text-left lg:mr-24">
      <div className="flex self-center rounded-full w-12 h-12 bg-white">
        <div
          className={`flex self-center rounded-full w-10 h-10 bg-${props.color} m-auto`}
        >
          <DynamicHeroIcon
            icon={props.icon}
            className="self-center text-white text-xs w-6 h-w-6 m-auto"
          />
        </div>
      </div>
      <div
        className={`flex-1 bg-white shadow-md rounded-md border-t-4 border-${props.color} px-4 py-2`}
      >
        <p className="font-bold text-gray-700 text-sm">{props.title}</p>
        <small className="text-gray-400 block -mt-0 text-xs">
          {props.text}
        </small>
      </div>
    </div>
  );
};

export const Timeline = () => {
  const [dimms, setDimms] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [height, setHeight] = useState(0);
  const [offsetTop, setOffsetTop] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const section1 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleResize() {
      setDimms({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const sec1 = section1.current as HTMLDivElement;
    setHeight(sec1.offsetHeight);
    setOffsetTop(sec1.offsetTop);
    setOffsetLeft(sec1.offsetLeft);
  });

  return (
    <div
      className="grid grid-cols-1 text-center lg:grid-cols-2 lg:mt-12 lg:text-left"
      ref={section1}
    >
      <div className="relative pb-12">
        <div className="lg:mr-7 lg:pt-14">
          <div
            className="absolute inset-0 overflow-hidden"
            style={{
              left: `-${offsetLeft}px`,
              top: "-80px",
              width: `${dimms.width}px`,
              height: `${height + 200}px`,
            }}
          >
            <div
              className="transform -rotate-6 2xl:-rotate-3 absolute"
              style={{
                backgroundColor: "#102A44",
                zIndex: -2,
                top: "70px",
                left: "-25%",
                width: `150%`,
                height: `80%`,
              }}
            />
            <div
              className="absolute right-0 bottom-24 w-96 h-1/3 lg:h-2/3"
              style={{
                backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.17'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
              }}
            ></div>
          </div>

          <h4 className="text-lg text-yellow-300 font-bold mt-20">
            Historial médico de pacientes
          </h4>
          <h2 className="text-4xl text-white font-bold">
            Rápido acceso a toda la información e historial del paciente
          </h2>
          <p className="text-gray-300 my-6 text-lg lg:text-base">
            Toda la información organizada en una ordenada línea de tiempo.
            <br />
            <strong>
              Consultas, Cirugías, Inmunizaciones, Desparasitaciones, Peluquería.
            </strong>
            <br />
            <br /> Veti permite guardar imágenes y resultados de exámenes en la ficha de la mascota. Además, envía automáticamente recordatorios a tu correo y al de tus clientes. Ahorra tiempo y no pierdas oportunidades.
          </p>
          <div className="flex space-x-2 justify-center lg:justify-start">
            <DynamicHeroIcon
              icon="BeakerIcon"
              className="text-white text-xs w-6 h-6"
            />
            <DynamicHeroIcon
              icon="ClipboardListIcon"
              className="text-white text-xs w-6 h-6"
            />
            <DynamicHeroIcon
              icon="ShieldCheckIcon"
              className="text-white text-xs w-6 h-6"
            />
            <DynamicHeroIcon
              icon="PresentationChartLineIcon"
              className="text-white text-xs w-6 h-6"
            />
          </div>
        </div>
      </div>
      <div className="relative lg:block lg:pt-12">
        <div className="relative">
          <div
            className="absolute left-6 top-4 h-4/5 border-l-2 border-white"
            style={{
              zIndex: -1,
            }}
          ></div>
          <div className="flex flex-col space-y-3">
            <TimelineEntry
              icon="ShieldExclamationIcon"
              title="Inmunización"
              text="19 julio 2019 - Dra. Daniela Muñoz"
              color="green-400"
            />
            <TimelineEntry
              icon="PlusCircleIcon"
              title="Consulta"
              text="20 Marzo 2019 - Dra. Daniela Muñoz"
              color="yellow-400"
            />
            <TimelineEntry
              icon="HeartIcon"
              title="Cirugía"
              text="13 Enero 2019 - Dr. Luis Orellana"
              color="blue-400"
            />
            <TimelineEntry
              icon="MinusCircleIcon"
              title="Desparasitación"
              text="14 Diciembre 2018 - Dr. Luis Orellana"
              color="green-600"
            />
            <TimelineEntry
              icon="ScissorsIcon"
              title="Peluquería"
              text="10 Diciembre 2018 - Dra. Daniela Muñoz"
              color="salmon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
