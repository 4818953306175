import { useState, useEffect, useRef } from "react";

export const Hero = () => {
  const [dimms, setDimms] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [offsetTop, setOffsetTop] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const section1 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleResize() {
      setDimms({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const sec1 = section1.current as HTMLDivElement;
    setOffsetTop(sec1.offsetTop + 50);
    setOffsetLeft(sec1.offsetLeft);
  });

  return (
    <div
      className="grid grid-cols-1 text-center lg:grid-cols-2 lg:mt-10 lg:text-left"
      ref={section1}
    >
      <div className="relative pb-12">
        <div className="lg:mr-7">
          <div
            className="absolute inset-0 overflow-hidden"
            style={{
              zIndex: -1,
              left: `-${offsetLeft}px`,
              top: `-${offsetTop}px`,
              width: `${dimms.width}px`,
            }}
          >
            <div
              className="bg-hero-pattern bg-no-repeat transform bg-left-bottom absolute"
              style={{
                top: "-50%",
                left: "-10%",
                width: `150%`,
                height: `150%`,
              }}
            />
            <div
              className="bg-gray-200 transform -rotate-6 absolute"
              style={{
                zIndex: -2,
                top: "-60%",
                left: "-50%",
                width: `150%`,
                height: `150%`,
              }}
            />
          </div>

          <h1 className="text-5xl text-gray-800 font-bold mt-20 mb-12 lg:mt-12 lg:text-6xl 2xl:mt-36">
            La App Veterinaria más{" "}
            <span className="text-indigo-400">simple</span>
          </h1>
          <p className="text-gray-500 my-6 text-lg lg:text-base">
            Estamos comprometidos con facilitar la vida de los veterinarios.
            <br />
            Dedica todo tu esfuerzo a lo que de verdad te apasiona, el amor por
            los animales
          </p>
          <a
            className="inline-block px-5 py-3 rounded-md transform transition bg-indigo-400 hover:bg-indigo-300 hover:-translate-y-0.5 focus:ring-indigo-500 focus:ring-opacity-50 focus:outline-none focus:ring focus:ring-offset-2 active:bg-indigo-600 uppercase tracking-wider font-semibold text-sm text-white shadow-lg sm:text-base"
            href="#demo-form"
          >
            Solicita tu demo
          </a>
        </div>
      </div>
      <div className="relative hidden lg:block">
        <img
          className="absolute inset-0 w-full h-full object-scale-down object-left-top"
          src="img/timeline_screens_webpage.png"
          alt="Pantalla de app Veti"
        />
      </div>
      <div className="lg:hidden">
        <img
          className="w-full h-full object-scale-down object-left-top"
          src="img/timeline_screens_webpage.png"
          alt="Pantalla de app Veti"
        />
      </div>
    </div>
  );
};
