import { DynamicHeroIcon } from "../components/DynamicHeroIcon";

interface FeatureProps {
  children: React.ReactNode;
  color: string;
  icon: string;
  title: string;
}

const Feature = ({ children, icon, color, title }: FeatureProps) => {
  return (
    <div className="bg-white p-5 mb-3 rounded-md shadow-lg flex md:w-11/12 xl:w-9/12 mx-auto">
      <div className={`rounded-full self-center h-8 w-8 ${color}`}>
        <DynamicHeroIcon icon={icon} />
      </div>
      <div className="flex-1 ml-6">
        <h4 className="text-gray-700 font-bold">{title}</h4>
        <p className="text-gray-500 text-sm">{children}</p>
      </div>
    </div>
  );
};

export const Features = () => {
  return (
    <div className="mt-12 lg:mt-16">
      <div className="lg:w-3/4 xl:w-3/6 flex flex-col mx-auto mb-8">
        <h2 className="text-3xl text-gray-700 font-bold lg:text-4xl text-center">
          En Veti nada sobra
        </h2>
        <p className="text-gray-500 my-6 text-lg lg:text-base text-center">
          Queremos que tu experiencia sea lo más sencilla y rápida posible.
          <br />
          Veti ayuda a los veterinarios a hacer más en menos tiempo, entregando
          un mejor servicio y logrando mejores resultados.
        </p>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1">
          <Feature title="Cloud" icon="CloudUploadIcon" color="text-yellow-400">
            Puedes usarlo desde el dispositivo que prefieras (computador, tablet
            o celular).
            <br />
            Siempre contarás con la última versión disponible.
          </Feature>
          <Feature title="Soporte" icon="UserGroupIcon" color="text-green-400">
            Cuando necesites ayuda, siempre habrá una persona a la cual puedas
            contactar.
          </Feature>
        </div>
        <div className="flex-1">
          <Feature title="Experto" icon="BeakerIcon" color="text-blue-400">
            Veti está hecho con la asesoría de médicos veterinarios y expertos
            en el área.
            <br />
            Además, gracias a las ideas y aportes de nuestros usuarios,&nbsp;
            <strong>
              estamos continuamente mejorándolo y adaptándonos a las necesidades
              de nuestros clientes.
            </strong>
          </Feature>
          <Feature title="Seguro" icon="ShieldCheckIcon" color="text-pink-500">
            Puedes estar tranquilo. Entendemos que la seguridad de tu
            información debe ser prioridad y la tenemos respaldada en todo
            momento.
          </Feature>
        </div>
      </div>
    </div>
  );
};
